<template>
  <section
    id="SiteTop"
    class="overflow-hidden"
  >
    <v-row no-gutters>
      <v-col
        class="primary text-center"
        cols="12"
        md="6"
      >
        <div class="vimeo-wrapper">
          <iframe
            id="vimeo"
            title="台湾ドラマ"
            src="https://player.vimeo.com/video/485070796?loop=1&title=0&byline=0&portrait=0"
            allow="autoplay; fullscreen"
            allowfullscreen
          />
        </div>
      </v-col>
      <v-col
        class="primary text-center white--text"
        cols="12"
        md="6"
      >
        <v-img
          :src="require('@/assets/taiwan-drama.jpg')"
          height="100%"
        >
          <div class="title-wrapper">
            <base-heading id="title">
              魅力いっぱい！<br>
              台湾ドラマ！！<br>
              <base-subheading>
                今すぐネットで<v-btn
                  :x-large="breakpoint.mdAndUp"
                  :large="breakpoint.sm"
                  icon
                  target="_blank"
                  rel="noopener"
                  href="https://www.google.com/search?q=%E5%8F%B0%E6%B9%BE%E3%83%89%E3%83%A9%E3%83%9E"
                >
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>！
              </base-subheading>
            </base-heading>
          </div>
          <!--<base-text>
            <p>このサイトは台湾のドラマを紹介するサイトです。</p>
          </base-text>-->
        </v-img>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  name: 'SiteTop',
  computed: {
    breakpoint () {
      return this.$vuetify.breakpoint
    }
  }
}
</script>

<style scoped>
.vimeo-wrapper {
  padding: 56.25% 0 0 0;
  position: relative;
}

#vimeo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-width: 0;
}

.title-wrapper {
  position: relative;
  height: 100%;
}

#title {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: rotate(-10deg) translateY(-50%);
  text-shadow: 3px 3px 1px #ffffff,
  -3px 3px 1px #ffffff,
  3px -3px 1px #ffffff,
  -3px -3px 1px #ffffff,
  3px 0 1px #ffffff,
  0 3px 1px #ffffff,
  -3px 0 1px #ffffff,
  0 -3px 1px #ffffff; /* 文字の影 */
}
</style>
